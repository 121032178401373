<template>
   <div class="card">
        <TabMenu :model="tab_items"  style="font-size: smaller;" />
        <tab-panels :animate="true" >
          <tab-panel  style="height:1000px">
            <loading
                :active="isLoadingModel"
                :can-cancel="true"
                
                color="#173EA3"
                :is-full-page="fullPage"
              >
            </loading>
            <div style="margin-top:20px;height:800px">
              <DashboardComponent></DashboardComponent>
            </div>
          </tab-panel>
        </tab-panels>
  </div>
</template>

<script>
import EventBus from '@/AppEventBus';
import ProductService from '../service/ProductService';
import apis from "@/apis";
import axios from "axios";
import { Buffer } from 'buffer'
import { embedDashboard } from "@superset-ui/embedded-sdk";
import GoogleMapComponent from '../components/GoogleMap'
import superset_dashboard from '../components/superset_dashboard'
import DashboardComponent from '../components/DashboardComponent'
import GoogleMapDashboard from '../components/GoogleMapDashboard'


import Map from "mapmyindia-react";
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';

import SocketioService from "../service/socketio.service.js";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { GoogleMap, Marker,MarkerCluster,InfoWindow,GMapMarker,Polyline } from "vue3-google-map";
import socketioService from '../service/socketio.service.js';
import { ref } from "vue";

export default {
	data() {
		return {
      mapstops:[],
      user_key:"",
      client_id_dropdown: [],
      client_id_search:'',
      routeitem:'',
      routeitems:[],
      isLoadingModel:false,
      pieOptions: null,
      tabs : ['Dashboard'],
      
       selectedTab:"Dashboard",
      pieData: {},
      pieData1: {},
			 center : { lat: 19.0626704, lng: 73.005094 },
			 center1 : {},
       centerstart:{},
       polyline:[      
],
polyline1:[      
],
           polypath : {

    },

      search_key:"",
			home:true,
			random:0,
      token:'',
			get_date:"",
			get_date_2:"",
			locations1:[],
			locations : [
      { lat: 19.0626704, lng: 73.005094,name:"Prathamesh" },
	  { lat: 28.38, lng: 77.12,name:"Sanjay" }
      
    ],
			markers:[
              {
                  position: [18.5314, 73.845],
                  draggable: true,
                  title: "Marker title"
			  }]
			  ,
			  messages: [],
      inputMessageText: "",
      
			  form: {
        roomName: "63a939f2b319a2f01eb75798",
        userName: "639c6caae0aa678e9f6de543",
      },
			products: null,
			todayslectures:[],
			dashcount:{
				"riders": 0,
				"drivers": 0,
				"driverowner": 0,
				"vehicles": 0,
				"owners": 0,
				"admin":0,
				"travels":0,
				"schools":0,
				"alerts":0,
				"today_alerts":0,
				"today_travels":0,
				"school_buses":0,
				
			},
			// center: [37,7749, -122,4194],

			lineData: {
				labels: ['0','0 to 20', '20 to 40', '40 to 60', '60 to 80', '80 to 100'],
				datasets: [
					{
						label: 'Network',
						data: [],
						backgroundColor: '#00bb7e',
						
					},
				
				]
			},
      travellist:[],
      travellist_filter:[],
			lastNumber:1,
			
      barData: {
				labels: [],
				height:"150px",
        datasets: [
					{
						label: 'Total Rides',
						backgroundColor: '#2f4860',
						data: []
					}
				]
			},
				barData1: {
				labels: [],
				datasets: [
					{
						label: 'No Of Lectures by Teacher',
						backgroundColor: '#2f4860',
						data: []
					}
				]
			},
			barOptions:null,
			barOptions1:null,
      tab_items : ref([{ label: 'Dashboard'}]),
			items: [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ],
			lineOptions: null,
		}
	},
	productService: null,
	themeChangeListener: null,
  
	async mounted() {
	
		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
		
    // await this.gettodaysTravelList();

	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	async created() {
    window.Buffer = window.Buffer || Buffer

		this.productService = new ProductService();
    this.user_key=localStorage.getItem("user_key");
    // await this.dashboardcount();
    // await this.dashboardRideData();
    // await this.route_list();
    // await this.get_client_list();
 
	},
	watch:{
    selectedTab()
    {
      if(this.selectedTab=="Dashboard")
      {
        // this.load_map_superset();
      }
    },
		polyline()
    {
      alert('ok');
      this.home=true;
      this.home=false;
    }
	},
	methods: {
    
    getaltimage()
    {
      return "https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o=";
    },
   
		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.barOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					},
          
        },
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
			this.barOptions1 = {
				indexAxis: 'y',
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	},
	components:{
    Tabs,
    Tab,
    TabPanels,
    superset_dashboard,
    DashboardComponent,
    GoogleMapDashboard,
    TabPanel,
    LTileLayer,
    LMarker
    
	}
}
</script>
<style>
</style>
<style scoped>
#mapContainer {
 width: 100%;
 height: 100vh;
}
.p-button-sm	
{
font-size: 0.7rem;

  padding: 0.4rem 0.8rem;
}
</style>