import { io } from "socket.io-client";

class SocketioService {
  socket;
  constructor() { }

  setupSocketConnection(token) {
    // alert(token);
    let header = {
      transports: ['websocket'] ,
      upgrade: false,
      authorization: token
    };
    this.socket = io('65.1.73.254:8090',{ extraHeaders:header});


    this.socket.on('position-change-broadcast', (data) => {
      // alert(data);
    });
  }

  // sendMessage(){
  //     this.socket.emit("position-change","User Moved to New Position");
  // }

  subscribeToMessages(cb) {
    console.log(cb)
    if (!this.socket) return (true);
    this.socket.on('message', msg => {
      console.log('Room event received!');
      return cb(null, msg);
    });
  }

  sendMessage({ message, roomName }) {
    if (this.socket) this.socket.emit('message', { message, roomName });
  }

  disconnect() {
    if(this.socket) {
      this.socket.disconnect();
    }
  }

}

export default new SocketioService();