<template>
<!-- {{polypath}} -->
<!-- {{polyline}} -->
			<!-- <Button style="text-align-right" label="Hide" @click="hide(1)" class="p-button-info p-button-sm	 mr-2 mb-2" />
            <Button style="text-align-right" label="Show" @click="hide(2)" class="p-button-info p-button-sm	 mr-2 mb-2" /> -->


    <!-- <GoogleMap  v-if="polyline_show"   ref="mapRef" id='maph' api-key="AIzaSyA62nfiBf_7XngEJxzZxltpRMbJjd2f7Fg" style="width: 100%; height: 95%;" :center="center1"  :zoom="18"> -->
    <GoogleMap  v-if="polyline_show"   ref="mapRef" id='maph' api-key="AIzaSyC-8ib8PMibUDDYoj5kzRzKwcusvw3Gztc" style="width: 100%; height: 95%;" :center="center1"  :zoom="18">
				    
        <Marker :options="{ position: centerstart }"  >
		</Marker>
        <Marker :options="{ position: center1 }"  >
		</Marker>
        <Marker :options="{ position: marker,title: 'stop',label: marker.stop_name,color:'green' ,icon:'http://maps.google.com/mapfiles/ms/icons/green-dot.png' }"   v-for="(marker, index) in markers" style="width:5px;height:5px">
		</Marker>
       


        <Polyline :options="polypath" />
		  
    </GoogleMap>
    
</template>
<script>
import { GoogleMap, Marker,Polyline } from "vue3-google-map";

export default {
    props: ['center1','centerstart','polyline','polypath','lat','stops'],
    data() {
        return {
            polyline_show:true,
            markers: [],
            flightPath : {
                path:[],
                geodesic: true,
                strokeColor: "blue",
                strokeOpacity: 0.5,
                strokeWeight: 5,
      
            },
             
            
            
        }
    },
    components:{
        GoogleMap, Marker,Polyline

    },
    computed: {
   
  },
    watch:{
        polypath:function(val){
    
            
            this.polyline.path=val.path;

           
            
            
        },
        lat:function(val)
        {
            // this.$forceUpdate();
            this.hide(1);
            setTimeout(function () { this.hide(2) }.bind(this), 50)

            // this.hide(2);
            // this.$refs.mapRef.show();


        },
    },
    created()
    {   
        var data=[];
        if (this.stops) 
        {
            for (let x = 0; x < this.stops.length; x++) 
            {   
            data.push({lat: parseFloat(this.stops[x].location.lat) , lng:parseFloat(this.stops[x].location.lng),'stop_name':this.stops[x].stop_name});
            }
        }
        
         this.markers=data;

         

         
    },
    methods:
    {
        hide(type='')
        {
            if(type==1)
            {
                this.polyline_show=false;
            }
            if(type==2)
            {
                this.polyline_show=true;
            }
        }
    //     //   const map = new google.maps.Map(document.getElementById("map"), {
    //     //     zoom: 8,
    //     //     center: { lat: 35.717, lng: 139.731 },
    //     // });

    },
    // mounted(){
    //     // this.polypath = {
    //     //         path: this.polyline,
    //     //         geodesic: true,
    //     //         strokeColor: '#FF0000',
    //     //         strokeOpacity: 1.0,
    //     //         strokeWeight: 2,
    //     //         }
            
    }
</script>