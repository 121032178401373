<template>
    <div id="supersetcontainer" style="width:100%"></div>
    <!-- Here -->
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import { Buffer } from 'buffer'
import { embedDashboard } from "@superset-ui/embedded-sdk";
export default {
    data() {
		return {
            isLoadingModel:false,
            token:'',
      
        }    
    },
    props: ["superset_id"],
    methods:
    {
        async superset_api()
        {   
            var id='';
            if (this.superset_id) {
                id=this.superset_id;
            }
            else{
                id="e7a76961-00c2-4c85-b0eb-2e3f71e207f0";
            }
            var data={
            id:id,
            type:"dashboard"
            };
            
        var promise = apis.superset_api(data);
            await promise
                .then(response => {
                // console.log(response);
                // alert(response.data.token);
                if (response.data) {
                    this.token=response.data.token;
                    return response.data.token;
                }
                
                
     
                }).catch((error) => {
  
                    this.isLoadingModel = false;
                 

                });
        },
    load_map_superset()
    {
        var id='';
            if (this.superset_id) {
                id=this.superset_id;
            }
            else{
                id="e7a76961-00c2-4c85-b0eb-2e3f71e207f0";
            }
      embedDashboard({
      id: id, // given by the Superset embedding UI
      supersetDomain: "http://3.110.177.178:8088",
      mountPoint: document.getElementById("supersetcontainer"), // html element in which iframe render
      fetchGuestToken: () => this.token,
      dashboardUiConfig: { hideTitle: true },
    });

    // const list = document.getElementsByTagName("iframe")[0];
    // console.log(list);
    // list.style.width="100%!important";
    // list.style.height="100%!important";
    // alert(screen.width);
    // var width=document.getElementById("supersetcontainer").style.width;
    // alert(width);
    var screensize = document.querySelectorAll("#supersetcontainer > iframe")
    screensize[0].style.width = "100%";
    screensize[0].style.height = "1500px";
    screensize[0].style.border = "none"

    },
    },
    async created()
    {
        // alert("1")
        await this.superset_api();
        this.load_map_superset();
    
    }
}
</script>